import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const ImportTestCase = () => {
  return (
    <LayoutComponent>
      <h1>Import Test Cases</h1>

      <p>
        Using our import wizard, you can import test cases into the GAINSS
        plugin from Comma-Separated Values (CSV) files.
      </p>
      <p>
        The import wizard simplifies the process, allowing you to map and import
        your test case data quickly and accurately.
      </p>

      <div className="testimonials">
        <h5>Tip</h5>
        <p>
          If your data is in Numbers or Excel, export it to CSV before importing
          into GAINSS. Note that Excel creates one CSV file per sheet, and some
          features, like formulas, will not be included.
        </p>
      </div>

      <h5>Importing Your Data to GAINSS</h5>

      <ol>
        <li>
          Click <strong>Import</strong> on the Test Cases main page.
        </li>
        <li>
          Upload your CSV file by dragging and dropping it into the designated
          area or by clicking <strong>Choose File</strong> and selecting it from
          your local storage. Your file will be uploaded.
        </li>
        <li>
          Specify the delimiter used in your CSV file (e.g., comma, semicolon).
        </li>
        <li>
          Click <strong>Next</strong>. The import progresses to the Field
          Mapping stage.
        </li>
        <div className="testimonials">
          <h5>Note</h5>
          <p>
            When importing test cases, you can include details such as the
            description, objective, and pre-condition. Status and priority
            fields will be automatically assigned default values.
          </p>
        </div>
        <li>
          Map your CSV columns to the corresponding GAINSS fields, then click{" "}
          <strong>Next</strong>. Columns that are not mapped will not be
          imported.
        </li>
        <li>
          Click <strong>Import</strong>. Your test cases data will be imported
          into GAINSS.
        </li>
      </ol>
    </LayoutComponent>
  )
}

export default ImportTestCase
